import { Button, Checkbox, Form, Input, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PhoneFilledSvg, TelegramFilledSvg } from "src/assets/svg";
import "./contact.scss";

function ContactSection() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);

  const [form] = Form.useForm();
  const [checked, setChecked] = useState(false);
  const onFinish = () => {
    message.success("Informs sent successfully");
    form.resetFields();
    setChecked(false);
  };

  return (
    <div className="section-contact" id="contact">
      <div className="contact-wrap container">
        <div
          className="contact-left"
          data-aos="fade-right"
          data-aos-delay="300"
          data-aos-duration="1500"
        >
          <h2>
            Contact us <span>for more</span>
          </h2>
          <div className="contact-left-connect">
            <div>
              <PhoneFilledSvg />
              <a href="tel:+12162935148">+1 (216) 293 51-48</a>
            </div>
            <div>
              <TelegramFilledSvg />
              <a
                href="https://t.me/leadereldsupport"
                target="_blanl"
                rel="noreferrer"
              >
                @leadereldsupport
              </a>
            </div>
          </div>
        </div>
        <div
          className="contact-right"
          data-aos="fade-left"
          data-aos-delay="300"
          data-aos-duration="1500"
        >
          <Form
            form={form}
            requiredMark={false}
            name="normal_login"
            className="form-form"
            // onFinish={onFinish}
            layout={"vertical"}
          >
            <Form.Item
              name="first_name"
              label="First name"
              rules={[
                { required: true, message: "Please enter your first name!" },
              ]}
              className="half"
            >
              <Input placeholder="First name" />
            </Form.Item>
            <Form.Item
              name="last_name"
              label="Last name"
              rules={[
                { required: true, message: "Please enter your last name!" },
              ]}
              className="half"
            >
              <Input placeholder="Last name" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: "Please enter your email!" }]}
              className="full"
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="phone_number"
              label="Phone Number"
              rules={[
                { required: true, message: "Please enter your phone number!" },
              ]}
              className="full"
            >
              <Input placeholder="(123) 456-7890" inputMode="numeric" />
            </Form.Item>
            <Form.Item name="message" label="Message" className="full">
              <TextArea
                placeholder="Message"
                autoSize={{ minRows: 4, maxRows: 6 }}
              />
            </Form.Item>
            <Form.Item name="remember" valuePropName="checked" label={null}>
              <Checkbox onClick={() => setChecked(!checked)} checked={checked}>
                <h3
                  style={{
                    fontSize: 12,
                    fontFamily: `"Poppins", sans-serif`,
                    fontWeight: 400,
                  }}
                >
                  By checking this bar, you agree to receive text messages from
                  LEADER ELD LLC. Please refer to our{" "}
                  <Link to="/privacy">privacy policy</Link>
                </h3>
              </Checkbox>
            </Form.Item>

            <Button
              onClick={onFinish}
              disabled={!checked}
              type="primary"
              className="button-filled"
            >
              Submit
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default ContactSection;
