import { Link } from "react-router-dom";
import { TelegramFilledSvg } from "src/assets/svg";
import "./footer.scss";

function FooterSection() {
  return (
    <div className="footer">
      <div className=" container footer-wrap">
        <div className="footer-info">
          <h4>LEADER ELD</h4>
          <p>
            FMCSA-certified ELD compliance solution, which makes life for truck
            drivers easier
          </p>
          <div className="footer-medias">
            <a
              href="https://t.me/leadereldsupport"
              target="_blank"
              rel="noreferrer"
            >
              <div>
                <TelegramFilledSvg />
              </div>
            </a>
          </div>
        </div>
        <div className="footer-columns">
          <p className="footer-title">LINKS</p>
          <a href="#products">Products</a>
          <a href="#features">Features</a>
          <a href="https://store.leadereld.com/">Store</a>
          <a href="#integrations">Integrations</a>
        </div>
        <div className="footer-columns">
          <p className="footer-title">USER AGREEMENT</p>
          <Link to="/terms-of-service">
            <p>Terms of Service</p>
          </Link>
          <Link to="/privacy">
            <p>Privacy Policy</p>
          </Link>
          <Link to="/returns">
            <p>Returns</p>
          </Link>
        </div>
        <div className="footer-columns">
          <p className="footer-title">ADDRESS</p>
          <p>USA</p>
          <p>
            <a href="tel:+12162935148">+1 (216) 293 51-48</a>
          </p>
          <button className="button-filled">Contact us</button>
        </div>
      </div>
      <p className="container footer-copyright ">
        Copyright 2025. All rights reserved
      </p>
    </div>
  );
}

export default FooterSection;
